'use client';

import { Category } from '@prezly/sdk';

import { useLocale } from '@/adapters/client';
import { StoryCard } from '@/components/StoryCards';
import type { ListStory } from '@/types';

import styles from './StoriesList.module.scss';

type Props = {
    stories: ListStory[];
    layout: 'blog' | 'news';
};

export function StoriesList({ stories, layout }: Props) {
    const locale = useLocale();

    if (layout === 'news') {
        const [firstStory, ...otherStories] = stories;
        return (
            <div className={styles.newsLayout}>
                <StoryCard
                    key={firstStory.uuid}
                    layout="vertical"
                    publishedAt={firstStory.published_at}
                    slug={firstStory.slug}
                    thumbnailImage={firstStory.thumbnail_image}
                    title={firstStory.title}
                    titleAsString={firstStory.title}
                    translatedCategories={Category.translations(firstStory.categories, locale)}
                    withFallbackImage
                />

                <div className={styles.tileStories}>
                    {otherStories.map((story) => (
                        <StoryCard
                            key={story.uuid}
                            layout="vertical"
                            publishedAt={story.published_at}
                            slug={story.slug}
                            title={story.title}
                            titleAsString={story.title}
                            translatedCategories={Category.translations(story.categories, locale)}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.storiesContainer}>
            {stories.map((story) => (
                <StoryCard
                    key={story.uuid}
                    layout="vertical"
                    publishedAt={story.published_at}
                    slug={story.slug}
                    thumbnailImage={story.thumbnail_image}
                    title={story.title}
                    titleAsString={story.title}
                    translatedCategories={Category.translations(story.categories, locale)}
                    withFallbackImage
                />
            ))}
        </div>
    );
}
