'use client';
import { useIntl } from '@prezly/theme-kit-nextjs';

import { translations } from '@/custom/i18n';

import Badge from './svg/badge.svg';
import Belgium from './svg/Belgium.svg';
import Building from './svg/building.svg';
import Handshake from './svg/handshake.svg';
import Money from './svg/money.svg';
import Person from './svg/person.svg';
import Sustainability from './svg/sustainability.svg';
import Trophy from './svg/trophy.svg';
import Users from './svg/users.svg';

export function useFeatures() {
    const { formatMessage } = useIntl();

    return [
        {
            icon: <Trophy />,
            value: formatMessage(translations.custom.insurerBelgiumValue),
            label: formatMessage(translations.custom.insurerBelgium),
        },
        {
            icon: <Person />,
            value: formatMessage(translations.custom.totalClientsValue),
            label: formatMessage(translations.custom.totalClients),
        },
        {
            icon: <Building />,
            value: formatMessage(translations.custom.distributionPartnersValue),
            label: formatMessage(translations.custom.distributionPartners),
        },
        {
            icon: <Users />,
            value: formatMessage(translations.custom.employeesValue),
            label: formatMessage(translations.custom.employees),
        },
        {
            icon: <Badge />,
            value: formatMessage(translations.custom.sustainableProductsValue),
            label: formatMessage(translations.custom.sustainableProducts),
        },
        {
            icon: <Handshake />,
            value: formatMessage(translations.custom.expertiseValue),
            label: formatMessage(translations.custom.expertise),
        },
        {
            icon: <Money />,
            value: formatMessage(translations.custom.assetsManagedValue),
            label: formatMessage(translations.custom.assetsManaged),
        },
        {
            icon: <Belgium />,
            value: formatMessage(translations.custom.investmentBelgiumValue),
            label: formatMessage(translations.custom.investmentBelgium),
        },
        {
            icon: <Sustainability />,
            value: formatMessage(translations.custom.sustainableInvestmentsValue),
            label: formatMessage(translations.custom.sustainableInvestments),
        },
    ];
}
