'use client';

import type { NewsroomContact } from '@prezly/sdk';
import { translations } from '@prezly/theme-kit-nextjs';

import { FormattedMessage, useLocale } from '@/adapters/client';
import { ContactCard } from '@/components/ContactCard';
import { Heading } from '@/components/Heading';
import { translations as customTranslations } from '@/custom/i18n';

import styles from './Contacts.module.scss';

interface Props {
    contacts: NewsroomContact[];
    withMoreLink: boolean;
}

export function Contacts({ contacts, withMoreLink }: Props) {
    const locale = useLocale();

    const link = withMoreLink
        ? {
              link: `/${locale}/contacts`,
              linkText: (
                  <FormattedMessage locale={locale} for={customTranslations.custom.showMore} />
              ),
          }
        : {};

    return (
        <div className={styles.contacts}>
            <div className={styles.container}>
                <Heading
                    text={<FormattedMessage locale={locale} for={translations.contacts.title} />}
                    {...link}
                />
                <div className={styles.grid}>
                    {contacts.map((contact) => (
                        <ContactCard
                            key={contact.uuid}
                            contactInfo={{
                                name: contact.name ?? '',
                                company: contact.company ?? '',
                                description: contact.description ?? '',
                                email: contact.email ?? '',
                                website: contact.website ?? '',
                                mobile: contact.mobile ?? '',
                                phone: contact.phone ?? '',
                                facebook: contact.facebook ?? '',
                                twitter: contact.twitter ?? '',
                            }}
                            avatar={contact.avatar_image}
                            uuid={contact.uuid}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
