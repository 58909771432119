'use client';

import classNames from 'classnames';

import { Link } from '@/components/Link';
import { ChevronRightIcon } from '@/custom/icons';

import styles from './Heading.module.scss';

interface Props {
    text: React.ReactNode;
    link?: string;
    linkText?: React.ReactNode;
    linkTarget?: string;
    size?: 'default' | 'large';
}

export function Heading({ text, link, linkText, linkTarget, size = 'default' }: Props) {
    function renderLink() {
        if (!link || !linkText) {
            return;
        }

        if (typeof link === 'string' && isExternalLink(link)) {
            return (
                <a href={link} className={styles.link} target={linkTarget}>
                    {linkText} <ChevronRightIcon className={styles.icon} width={12} height={12} />
                </a>
            );
        }

        return (
            <Link href={link} className={styles.link}>
                {linkText} <ChevronRightIcon className={styles.icon} width={12} height={12} />
            </Link>
        );
    }

    return (
        <div className={styles.heading}>
            <h2
                className={classNames({
                    [styles.default]: size === 'default',
                    [styles.large]: size === 'large',
                })}
            >
                {text}
            </h2>
            {renderLink()}
        </div>
    );
}

function isExternalLink(link: string): boolean {
    return link.startsWith('https://') || link.startsWith('http://');
}
