'use client';

import { FormattedMessage, Locale } from '@prezly/theme-kit-nextjs';

import { Heading } from '@/components/Heading';
import { translations } from '@/custom/i18n';

import { useFeatures } from './useFeatures';

import styles from './Features.module.scss';

interface Props {
    localeCode: Locale.Code;
}

export function Features({ localeCode }: Props) {
    const features = useFeatures();
    const { code } = Locale.from(localeCode);

    const isNl = code.includes('nl');

    return (
        <section className={styles.features}>
            <Heading
                text={<FormattedMessage for={translations.custom.keyFigures} locale={localeCode} />}
                link={
                    isNl
                        ? 'https://ag.be/over-ag/nl/wie-zijn-wij/kerncijfers'
                        : 'https://ag.be/a-propos-d-ag/fr/qui-sommes-nous/chiffres-clefs'
                }
                linkText={
                    <FormattedMessage locale={localeCode} for={translations.custom.showMore} />
                }
                linkTarget="_blank"
            />
            <div className={styles.grid}>
                {features.map((feature, index) => (
                    <div key={index} className={styles.feature}>
                        <div className={styles.icon}>{feature.icon}</div>
                        <div className={styles.value}>{feature.value}</div>
                        <div className={styles.label}>{feature.label}</div>
                    </div>
                ))}
            </div>
        </section>
    );
}
