import { defineMessages } from '../defineMessage';

export const custom = defineMessages({
    showMore: {
        id: 'custom.showMore',
        defaultMessage: 'Show more',
    },
    all: {
        id: 'custom.all',
        defaultMessage: 'All',
    },
    pressMaterial: {
        id: 'custom.pressMaterial',
        defaultMessage: 'Press material',
    },
    images: {
        id: 'custom.images',
        defaultMessage: 'Images',
    },
    logos: {
        id: 'custom.logos',
        defaultMessage: 'Logos',
    },
    publications: {
        id: 'custom.publications',
        defaultMessage: 'Publications',
    },
    corporateProfile: {
        id: 'custom.corporateProfile',
        defaultMessage: 'Corporate Profile',
    },
    blogs: {
        id: 'custom.blogs',
        defaultMessage: 'Blogs',
    },
    news: {
        id: 'custom.news',
        defaultMessage: 'News',
    },
    numberOne: {
        id: 'custom.numberOne',
        defaultMessage: 'Insurer in Belgium',
    },
    numberOneValue: {
        id: 'custom.numberOneValue',
        defaultMessage: 'Nr. 1',
    },
    investmentBelgium: {
        id: 'custom.investmentBelgium',
        defaultMessage: 'Investments in Belgium',
    },
    investmentBelgiumValue: {
        id: 'custom.investmentBelgiumValue',
        defaultMessage: '27 billion euros',
    },
    employees: {
        id: 'custom.employees',
        defaultMessage: 'Employees',
    },
    employeesValue: {
        id: 'custom.employeesValue',
        defaultMessage: '+4,400',
    },
    expertise: {
        id: 'custom.expertise',
        defaultMessage: 'Years of expertise',
    },
    expertiseValue: {
        id: 'custom.expertiseValue',
        defaultMessage: '+200',
    },
    netProfit: {
        id: 'custom.netProfit',
        defaultMessage: 'Euro net profit (2023)',
    },
    netProfitValue: {
        id: 'custom.netProfitValue',
        defaultMessage: '658 million euros',
    },
    privateClients: {
        id: 'custom.privateClients',
        defaultMessage: 'Private clients',
    },
    privateClientsValue: {
        id: 'custom.privateClientsValue',
        defaultMessage: '2.7 million',
    },
    businessClients: {
        id: 'custom.businessClients',
        defaultMessage: 'Business clients',
    },
    businessClientsValue: {
        id: 'custom.businessClientsValue',
        defaultMessage: '280,000',
    },
    topEmployer: {
        id: 'custom.topEmployer',
        defaultMessage: 'Top Employer',
    },
    topEmployerValue: {
        id: 'custom.topEmployerValue',
        defaultMessage: '13 times',
    },
    householdsTrust: {
        id: 'custom.householdsTrust',
        defaultMessage: 'Belgian households trust us',
    },
    householdsTrustValue: {
        id: 'custom.householdsTrustValue',
        defaultMessage: '1 in 2',
    },
    distributionPartners: {
        id: 'custom.distributionPartners',
        defaultMessage: 'Distribution partners',
    },
    distributionPartnersValue: {
        id: 'custom.distributionPartnersValue',
        defaultMessage: '>5,000',
    },
    agAcademyParticipants: {
        id: 'custom.agAcademyParticipants',
        defaultMessage: 'AG Academy participants/year',
    },
    agAcademyParticipantsValue: {
        id: 'custom.agAcademyParticipantsValue',
        defaultMessage: '18,000',
    },
    totalInvestments: {
        id: 'custom.totalInvestments',
        defaultMessage: 'Total investments',
    },
    totalInvestmentsValue: {
        id: 'custom.totalInvestmentsValue',
        defaultMessage: '67 billion euros',
    },
    keyFigures: {
        id: 'custom.keyFigures',
        defaultMessage: 'Key Figures',
    },
    individuals: {
        id: 'custom.individuals',
        defaultMessage: 'Individuals',
    },
    smes: {
        id: 'custom.smes',
        defaultMessage: 'Self-employed & SMEs',
    },
    employeeBenefits: {
        id: 'custom.employeeBenefits',
        defaultMessage: 'Employee Benefits',
    },
    allWebsites: {
        id: 'custom.allWebsites',
        defaultMessage: 'All our websites',
    },
    privacy: {
        id: 'custom.privacy',
        defaultMessage: 'Privacy Policy',
    },
    terms: {
        id: 'custom.terms',
        defaultMessage: 'Terms of Use',
    },
    cookies: {
        id: 'custom.cookies',
        defaultMessage: 'Cookies',
    },
    contracts: {
        id: 'custom.contracts',
        defaultMessage: 'General Terms and Contractual Documents',
    },
    report: {
        id: 'custom.report',
        defaultMessage: 'Submit a Report',
    },
    copyright: {
        id: 'custom.copyright',
        defaultMessage: '© 2024 AG, All rights reserved',
    },
    jobs: {
        id: 'custom.jobs',
        defaultMessage: 'Jobs',
    },
    aboutAg: {
        id: 'custom.aboutAg',
        defaultMessage: 'About AG',
    },
    newsroom: {
        id: 'custom.newsroom',
        defaultMessage: 'Newsroom',
    },
    sustainability: {
        id: 'custom.sustainability',
        defaultMessage: 'Sustainability',
    },
    insurerBelgium: {
        id: 'custom.insurerBelgium',
        defaultMessage: 'Insurer in Belgium',
    },
    insurerBelgiumValue: {
        id: 'custom.insurerBelgiumValue',
        defaultMessage: 'Nr. 1',
    },
    totalClients: {
        id: 'custom.totalClients',
        defaultMessage: 'Clients',
    },
    totalClientsValue: {
        id: 'custom.totalClientsValue',
        defaultMessage: '+3 million',
    },
    sustainableProducts: {
        id: 'custom.sustainableProducts',
        defaultMessage: 'Products with Towards Sustainability label',
    },
    sustainableProductsValue: {
        id: 'custom.sustainableProductsValue',
        defaultMessage: '42',
    },
    assetsManaged: {
        id: 'custom.assetsManaged',
        defaultMessage: 'Assets under management',
    },
    assetsManagedValue: {
        id: 'custom.assetsManagedValue',
        defaultMessage: '+73 billion EUR',
    },
    sustainableInvestments: {
        id: 'custom.sustainableInvestments',
        defaultMessage: 'Sustainable investments',
    },
    sustainableInvestmentsValue: {
        id: 'custom.sustainableInvestmentsValue',
        defaultMessage: '+16 billion EUR',
    },
});
