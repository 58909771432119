'use client';

import { FormattedMessage, Locale, useIntl } from '@prezly/theme-kit-nextjs';

import type { AppUrlGeneratorParams } from '@/adapters/client';
import { Heading } from '@/components/Heading';
import { Link } from '@/components/Link';
import { translations } from '@/custom/i18n';
import { ArrowRightIcon } from '@/custom/icons';

import styles from './PressMaterial.module.scss';

interface Props {
    localeCode: Locale.Code;
    withShowMoreLink?: boolean;
}

export function PressMaterial({ localeCode, withShowMoreLink = false }: Props) {
    const { formatMessage } = useIntl();
    const { code } = Locale.from(localeCode);
    const isNl = code.includes('nl');

    const items = [
        {
            label: formatMessage(translations.custom.images),
            link: { routeName: 'media', params: { localeCode } },
        },
        {
            label: formatMessage(translations.custom.publications),
            link: isNl ? '/publicaties' : '/publications',
        },
        {
            label: formatMessage(translations.custom.corporateProfile),
            link: isNl ? '/bedrijfsprofiel' : '/profil-de-lentreprise',
        },
    ] satisfies Array<{ label: string; link: string | AppUrlGeneratorParams }>;

    return (
        <section className={styles.pressMaterial}>
            <div className={styles.container}>
                <Heading
                    text={
                        <FormattedMessage
                            locale={localeCode}
                            for={translations.custom.pressMaterial}
                        />
                    }
                    link={withShowMoreLink ? `/${localeCode}/media` : undefined}
                    linkText={
                        withShowMoreLink ? (
                            <FormattedMessage
                                locale={localeCode}
                                for={translations.custom.showMore}
                            />
                        ) : undefined
                    }
                />
                <div className={styles.grid}>
                    {items.map((item, index) => (
                        <Link key={index} href={item.link} className={styles.item}>
                            {item.label}
                            <ArrowRightIcon width={16} height={16} />
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
}
