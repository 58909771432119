'use client';

import type { ContactNode } from '@prezly/content-format';
import type { UploadedImage } from '@prezly/sdk';
import UploadcareImage from '@uploadcare/nextjs-loader';
import classNames from 'classnames';

import { Card } from '@/components/Card';
import { IconEmail, IconFacebook, IconGlobe, IconMobile, IconPhone, IconTwitter } from '@/icons';
import { getUploadcareImage } from '@/utils/getUploadcareImage';

import type { ContactInfo } from './types';
import { getSocialHandles } from './utils';

import styles from './ContactCard.module.scss';

interface Props {
    className?: string;
    contactInfo: ContactInfo;
    avatar: UploadedImage | null;
    uuid: ContactNode['uuid'];
}
export function ContactCard({ className, contactInfo, avatar, uuid }: Props) {
    const { name, email, phone, mobile, website, description } = contactInfo;
    const { facebook, twitter } = getSocialHandles(contactInfo);
    const image = getUploadcareImage(avatar);

    return (
        <Card id={`contact-${uuid}`} className={classNames(styles.container, className)}>
            {image && (
                <div className={styles.avatar}>
                    <UploadcareImage
                        className={styles.image}
                        src={image.cdnUrl}
                        width={256}
                        height={256}
                        alt={name}
                    />
                </div>
            )}
            <div className={styles.content}>
                <h4 className={styles.name}>{name}</h4>
                {description && <p className={styles.description}>{description}</p>}
                <div className={styles.links}>
                    {email && (
                        <div className={styles.contact}>
                            <IconEmail className={styles.icon} />
                            <a href={`mailto:${email}`} className={styles.link}>
                                {email}
                            </a>
                        </div>
                    )}
                    {phone && (
                        <div className={styles.contact}>
                            <IconPhone className={styles.icon} />
                            <a href={`tel:${phone}`} className={styles.link}>
                                {phone}
                            </a>
                        </div>
                    )}
                    {mobile && (
                        <div className={styles.contact}>
                            <IconMobile className={styles.icon} />
                            <a href={`tel:${mobile}`} className={styles.link}>
                                {mobile}
                            </a>
                        </div>
                    )}
                    {website && (
                        <div className={styles.contact}>
                            <IconGlobe className={styles.icon} />
                            <a href={website} className={styles.link}>
                                {website.replace(/(^\w+:|^)\/\//, '')}
                            </a>
                        </div>
                    )}
                    {facebook && (
                        <div className={styles.contact}>
                            <IconFacebook className={styles.icon} />
                            <a href={`https://facebook.com/${facebook}`} className={styles.link}>
                                {facebook}
                            </a>
                        </div>
                    )}
                    {twitter && (
                        <div className={styles.contact}>
                            <IconTwitter className={styles.icon} />
                            <a href={`https://twitter.com/${twitter}`} className={styles.link}>
                                {`@${twitter}`}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
}
